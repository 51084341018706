import { useState, useEffect } from "react";
import {
  createStyles,
  Header,
  Container,
  Burger,
  Paper,
  Transition,
  rem,
  Box,
  Image,
  UnstyledButton,
  Text,
  Center,
  Menu,
  Group,
  Flex,
  SegmentedControl,
} from "@mantine/core";

import { ChevronDown } from "tabler-icons-react";

import { NavLink, useLocation } from "react-router-dom";

import { useDisclosure, useMediaQuery } from "@mantine/hooks";

const HEADER_HEIGHT = rem(80);

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  linkLabel: {
    marginRight: "5px",
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkDropdown: {
    width: "190px",
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

// const links1 = [
//   {
//     link: "/about",
//     label: "About Me",
//     element: "about",
//     external: false,
//   },
// ];

const links2 = [
  {
    link: "/services",
    label: "Boek Tsnatee als...",
    links: [
      { link: "/speaker", label: "Spreker" },
      { link: "/trainer", label: "Trainer" },
      { link: "/presenter", label: "Dagvoorzitter" },
    ],
  },
];

const links3 = [
  {
    link: "/contact",
    label: "Contact",
    // element: "contact",
    // external: false,
  },
];

export function HeaderResponsive() {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState();
  const { classes, cx } = useStyles();

  const matches = useMediaQuery("(min-width: 56.25em)");

  const items2 = links2.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item
        component={NavLink}
        to={`${link.link}${item.link}`}
        key={item.link}
      >
        {item.label}
      </Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu
          key={link.label}
          transitionProps={{ exitDuration: 0 }}
          withinPortal
        >
          <Menu.Target>
            <a
              href={link.link}
              className={classes.linkDropdown}
              onClick={(event) => event.preventDefault()}
            >
              <Flex direction="row" gap={2}>
                <span className={classes.linkLabel}>{link.label}</span>
                <ChevronDown size="0.9rem" />
              </Flex>
            </a>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }
  });

  const location = useLocation();

  useEffect(() => {
    close();
  }, [location]);

  return (
    <>
      {/* <Box h="60px" bg="linear-gradient(to right, #7b0144, #fada01);">
        <Center h={60}>
          <UnstyledButton
            component="a"
            target="_blank"
            href="https://shop.tsnatee.com/checkout/career-journal"
          >
            <Text color="white" size={matches ? "md" : "xs"} weight="800">
              ✨ Geef je carrière een boost met de Career Journal! >>
            </Text>
          </UnstyledButton>
        </Center>
      </Box> */}
      <Box h="60px" bg="linear-gradient(to right, #7b0144, #fada01);">
        <Center h={60}>
          <UnstyledButton
            component="a"
            target="_blank"
            href="https://tsnatee.com/brandbrunch"
          >
            <Text color="white" size={matches ? "md" : "xs"} weight="800">
              ✨ Plan vandaag jouw Brand Brunch >>
            </Text>
          </UnstyledButton>
        </Center>
      </Box>
      <Header height={HEADER_HEIGHT} className={classes.root}>
        <Container className={classes.header}>
          <Box w={120} component={NavLink} to="/">
            <Image
              maw={120}
              h="60px"
              radius="md"
              src="./logo_optimized_vector.svg"
              alt="Logo"
            />
          </Box>

          <Group spacing={5} className={classes.links}>
            {items2}

            <NavLink
              className={classes.link}
              to="brandbrunch"
              key="brandbrunch"
            >
              Brand Brunch
            </NavLink>

            <NavLink
              className={classes.link}
              to="careerjournal"
              key="careerjournal"
            >
              Career Journal
            </NavLink>

            <NavLink className={classes.link} to="contact" key="contact">
              Contact
            </NavLink>
          </Group>

          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size="sm"
          />

          <Transition
            transition="pop-top-right"
            duration={200}
            mounted={opened}
          >
            {(styles) => (
              <Paper className={classes.dropdown} withBorder style={styles}>
                {items2}
                <NavLink
                  className={classes.link}
                  to="brandbrunch"
                  key="brandbrunch"
                >
                  Brand Brunch
                </NavLink>
                <NavLink
                  className={classes.link}
                  to="careerjournal"
                  key="careerjournal"
                >
                  Career Journal
                </NavLink>
                <NavLink className={classes.link} to="contact" key="contact">
                  Contact
                </NavLink>
              </Paper>
            )}
          </Transition>
        </Container>
      </Header>
    </>
  );
}

export default HeaderResponsive;

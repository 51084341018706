import React, { useEffect, useRef } from "react";
import {
  Center,
  Title,
  Box,
  BackgroundImage,
  Image,
  Text,
  List,
  ThemeIcon,
  Stack,
  Group,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { Checks } from "tabler-icons-react";

const Content = () => {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box
      w="100%"
      sx={(theme) => ({
        borderRadius: "5px",
      })}
    >
      <Stack mx="1rem" spacing="xl">
        <Center>
          <Title
            order={1}
            size={matches ? "2.5rem" : "1.8rem"}
            weight={600}
            align="center"
            sx={{ maxWidth: "800px" }}
          >
            Free Masterclass: Personal Branding for Corporate Success – Land
            Your Dream Job in 2025
          </Title>
        </Center>

        <Text
          size="lg"
          align="center"
          sx={{ maxWidth: "800px", margin: "0 auto" }}
        >
          In this 30-minute session, personal branding expert Tsnatee shares her
          proven strategies to help you stand out, get noticed, and secure your
          next big role.
        </Text>

        <Box mt="2rem">
          <Title order={3} weight={600} mb="1rem" align="center">
            Join this free masterclass to learn:
          </Title>

          <List
            spacing="md"
            size="lg"
            center
            sx={{ maxWidth: "800px", margin: "0 auto" }}
          >
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              How to craft a LinkedIn profile that gets recruiters' attention
            </List.Item>

            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Simple steps to showcase your expertise authentically online
            </List.Item>

            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              How to stand out in interviews with confidence and clarity
            </List.Item>

            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Tips to build a professional network that advocates for you
            </List.Item>

            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Actionable strategies to align your personal brand with your dream
              job
            </List.Item>
          </List>
        </Box>

        <Box mt="2rem">
          <Title
            order={2}
            weight={600}
            align="center"
            color="#7b0144"
            size={matches ? "2rem" : "1.5rem"}
          >
            Let's make 2025 the year you achieve your career goals!
          </Title>

          <Text
            size="sm"
            align="center"
            mt="1rem"
            sx={{ maxWidth: "600px", margin: "1rem auto" }}
          >
            By signing up, you'll gain access to practical insights that will
            elevate your professional brand.
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

// Custom hook to load the ActiveHosted script
const useActiveHostedScript = (formId, containerRef) => {
  useEffect(() => {
    if (!containerRef.current) return;

    const loadForm = () => {
      // Create form container
      const formDiv = document.createElement("div");
      formDiv.className = `_form_${formId}`;
      containerRef.current.innerHTML = "";
      containerRef.current.appendChild(formDiv);

      // Load the script
      const script = document.createElement("script");
      script.src = `https://tsnatee.activehosted.com/f/embed.php?id=${formId}`;
      script.charset = "utf-8";
      script.async = true;

      const handleScriptLoad = () => {
        if (window._form_callback) {
          window._form_callback();
        }

        // After the form loads, remove any duplicate forms that aren't in our container
        setTimeout(() => {
          const allForms = document.querySelectorAll(`._form_${formId}`);
          allForms.forEach((form) => {
            // Check if this form is NOT inside our container
            if (!containerRef.current.contains(form)) {
              // If it's not in our container, remove it
              form.parentElement?.remove(); // Remove the parent div as well
            }
          });
        }, 100); // Small delay to ensure the script has finished creating forms
      };

      script.addEventListener("load", handleScriptLoad);
      document.body.appendChild(script);

      return () => {
        script.removeEventListener("load", handleScriptLoad);
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    };

    const cleanup = loadForm();

    // Monitor for any dynamically added forms and remove duplicates
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLElement) {
              const duplicateForms = node.querySelectorAll(`._form_${formId}`);
              duplicateForms.forEach((form) => {
                if (!containerRef.current.contains(form)) {
                  form.parentElement?.remove();
                }
              });
            }
          });
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      cleanup();
      observer.disconnect();
    };
  }, [formId]);
};

const Form = () => {
  const matches = useMediaQuery("(min-width: 56.25em)");
  const formContainerRef = useRef(null);

  useActiveHostedScript("1", formContainerRef);

  return (
    <Box
      id="calendar"
      mx="auto"
      p={20}
      w={matches ? 700 : "100%"}
      ref={formContainerRef}
    />
  );
};

const Hero = () => {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box>
      <BackgroundImage radius="xs">
        <Box
          sx={() => ({
            background: "linear-gradient(to right, #7b0144, #fada01)",
            height: 300,
          })}
        >
          <Center>
            <Box
              sx={() => ({
                textAlign: "center",
                paddingTop: 120,
              })}
            >
              <Title
                order={2}
                c="white"
                size={matches ? "2.5rem" : "1rem"}
                fw={600}
              >
                LET'S MAKE IT HAPPEN, TOGETHER
              </Title>
              <Center>
                <Stack>
                  <Center>
                    <Image
                      maw={100}
                      h="60px"
                      radius="md"
                      src="./logo_optimized_vector.svg"
                      alt="Logo"
                    />
                  </Center>
                </Stack>
              </Center>
            </Box>
          </Center>
        </Box>
      </BackgroundImage>
    </Box>
  );
};

const MasterClassPage = () => {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box pb={20}>
      {/* <Hero /> */}
      <Box mx="auto" px={20} mt={20}>
        <Group
          align="flex-start"
          spacing={40}
          grow={matches}
          sx={{
            flexDirection: matches ? "row" : "column",
          }}
        >
          <Box sx={{ flex: "1" }}>
            <Content />
          </Box>
          <Box
            sx={{
              flex: "0 0 auto",
              width: matches ? "400px" : "100%",
            }}
          >
            <Form />
          </Box>
        </Group>
      </Box>
    </Box>
  );
};

export default MasterClassPage;

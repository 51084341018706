import React, { useState } from "react";
import {
  TextInput,
  Notification,
  Button,
  Box,
  Input,
  SimpleGrid,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const NewsLetter = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const recaptchaRef = React.createRef();

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
    },
    validate: {
      name: (value) =>
        value.trim().length < 2 ? "Name must be at least 2 characters" : null,
      email: (value) =>
        !/^\S+@\S+$/.test(value) ? "Invalid email address" : null,
    },
  });

  const onSubmit = async (values) => {
    if (!captchaVisible) {
      setCaptchaVisible(true);
      return; // Prevent form submission until captcha is solved
    }

    const recaptchaValue = recaptchaRef.current.getValue();
    setLoading(true);

    try {
      const response = await axios.post("/signup", {
        ...values,
        "g-recaptcha-response": recaptchaValue,
      });
      setResult(response.data.responseCode === 0 ? "success" : "error");
    } catch (error) {
      setResult("error");
    } finally {
      setLoading(false);
      setCaptchaVisible(false); // Optionally hide captcha after submission
    }
  };

  // Check if both name and email are filled out to show captcha
  const shouldShowCaptcha = () => {
    const { values } = form;
    return values.name.trim() && values.email.trim();
  };

  return (
    <>
      {loading && (
        <Notification
          withCloseButton={false}
          loading
          title="Inschrijven..."
          color="yellow"
        >
          Laden...
        </Notification>
      )}

      {result === "success" && (
        <Notification
          withCloseButton={false}
          title="Succesvol ingeschreven!"
          color="green"
        >
          Dank voor het inschrijven.
        </Notification>
      )}

      {result === "error" && (
        <Notification
          withCloseButton={false}
          title="Something went wrong..."
          color="red"
        >
          Unfortunately, something went wrong. Please try again later.
        </Notification>
      )}

      {!loading && !result && (
        <form onSubmit={form.onSubmit(onSubmit)}>
          <SimpleGrid
            justify="end"
            breakpoints={[
              { minWidth: "sm", cols: 1 },
              { minWidth: "md", cols: 2 },
              { minWidth: "lg", cols: 3 },
            ]}
          >
            <TextInput
              label={<Input.Label c="white">Naam</Input.Label>}
              placeholder="naam"
              mt="xs"
              {...form.getInputProps("name")}
            />

            <TextInput
              label={<Input.Label c="white">E-mail</Input.Label>}
              placeholder="E-mail"
              mt="xs"
              {...form.getInputProps("email")}
            />

            {shouldShowCaptcha() && (
              <Box mt="xs">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LdMJF4pAAAAAEcjHC0Q4s5tbNZPvUmiV1mB88UN"
                  onChange={() => setCaptchaVisible(true)}
                />
              </Box>
            )}

            <Button
              type="submit"
              variant="outline"
              mt="xs"
              sx={(theme) => ({
                borderColor: "white",
                alignSelf: "flex-end",
              })}
              c="white"
            >
              Inschrijven
            </Button>
          </SimpleGrid>
        </form>
      )}
    </>
  );
};

export default NewsLetter;

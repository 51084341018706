import { useRef } from "react";

import {
  Center,
  Title,
  Text,
  Flex,
  Box,
  BackgroundImage,
  Stack,
  Image,
  Button,
  Paper,
  SimpleGrid,
  UnstyledButton,
  Blockquote,
} from "@mantine/core";

import Autoplay from "embla-carousel-autoplay";

import Contact from "./ContactForm";

import { Carousel } from "@mantine/carousel";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

function Hero() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box>
      <BackgroundImage src="/hero.jpg" radius="xs">
        <Box
          sx={(theme) => ({
            height: "900px",
          })}
        >
          <Center>
            <Box
              sx={(theme) => ({
                width: "600px",
                textAlign: "center",
                paddingTop: "400px",
              })}
            >
              <Title
                order={2}
                c="white"
                size={matches ? "3rem" : "2rem"}
                fw="600"
                color="black"
              >
                EMPOWERING AND ENERGIZING BRANDS & PEOPLE
              </Title>

              <Center>
                <Image
                  maw={150}
                  radius="md"
                  src="./logo_optimized_vector.svg"
                  alt="Logo"
                />
              </Center>
            </Box>
          </Center>
        </Box>
      </BackgroundImage>
    </Box>
  );
}

function IntroductionEnglish() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  return (
    <Box id="about" mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Stack>
        <Box
          sx={(theme) => ({
            background: "#7C0144",
            color: "white",
            textAlign: "center",
            padding: 5,
            borderRadius: "5px",
          })}
        >
          <Title>Get to know Tsnatee.</Title>
        </Box>
        <Text size="md">
          Tsnatee Elisa embodies professionalism with a clear sense of purpose,
          a dynamic presence, and unwavering precision in everything she does.
          Whether she's on stage as a keynote speaker, moderating events,
          carefully crafting and hosting workshops, or providing valuable
          guidance as a brand consultant, she consistently delivers excellence.
          Her ability to inspire{" "}
          <Text component="span" fw={600}>
            enthusiasm{" "}
          </Text>
          and inject{" "}
          <Text component="span" fw={600}>
            energy
          </Text>{" "}
          into any setting is remarkable. Whether it's a classroom, a virtual
          meeting or a grand theater, Tsnatee has an exceptional talent for
          uplifting everyone's spirits.
        </Text>
        <Text size="md">
          What truly distinguishes Tsnatee is her extensive knowledge and
          expertise. She brings a diverse background in marketing, branding,
          sales, and event management, along with significant experience on
          stage and in front of the camera as a singer, dancer, presenter, and
          moderator. This unique combination of skills establishes her as an
          unmatched authority in the fields of marketing communication, event
          coordination, and advertising.
        </Text>
        <Text size="md">
          Furthermore, Tsnatee boasts extensive experience in the corporate
          environment, having served as a marketing manager at Dell
          Technologies. Throughout her corporate journey, she remained committed
          to pursuing her passion alongside her 9-5 commitments, and this
          endeavor has become her primary business focus. Drawing from her
          corporate background, she comprehends the inner workings of businesses
          and the dedication required to excel. Consequently, she has developed
          three workshops centered on topics that have been close to her heart
          since her corporate days:{" "}
          <Text component="span" fw={600}>
            Personal Branding
          </Text>
          ,{" "}
          <Text component="span" fw={600}>
            Career Happiness
          </Text>
          , and{" "}
          <Text component="span" fw={600}>
            Diversity & Inclusion
          </Text>
          . Her overarching mission is to invigorate and empower individuals to
          authentically derive enjoyment from their 9-5 jobs, thrive in their
          careers, and contribute to the cultivation of a culturally intelligent
          workforce.
        </Text>
        <Text size="md">
          Tsnatee's areas of expertise are broad and deeply impactful. She holds
          a particular passion for technology, personal branding, digital
          marketing, impactful branding strategies, career development,
          diversity and inclusion, women's empowerment, marketing strategy,
          motivation, media, pop culture, and the role of women in corporate
          leadership.
        </Text>
        <Text size="lg" fw={600} align="center">
          If you're seeking an energetic professional who can infuse life into
          your workforce, expertly guide your audience and guests with
          precision, and leave them with a lasting sense of positivity, then
          Tsnatee is the ideal choice.
        </Text>
      </Stack>
    </Box>
  );
}

function IntroductionDutch() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  return (
    <Box id="about" mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Stack>
        <Box
          sx={(theme) => ({
            background: "#7C0144",
            color: "white",
            textAlign: "center",
            padding: 5,
            borderRadius: "5px",
          })}
        >
          <Title>Get to know Tsnatee.</Title>
        </Box>
        <Text>
          Tsnatee (Snaa-Tee) is een always on & always on fire presentatrice,
          spreker, trainer, dagvoorzitter en consultant die zich volledig inzet
          om jouw evenement naar het volgende level te tillen. Met een focus op
          personal branding, loopbaanontwikkeling, diversiteit, gelijkheid en
          inclusie (DEI), brengt ze naast haar ongekende energie en enthousiasme
          ook haar ervaring in de podiumkunsten, tv-, marketing- en event-wereld
          mee om van jouw event een onvergetelijke ervaring te maken.
        </Text>

        <Text size="md">
          Haar gedrevenheid komt voort uit de wens om professionals te
          inspireren om hun eigen podium te creëren en niet te wachten op de
          kans om te shinen, maar deze zelf actief te grijpen. Tsnatee spoort
          professionals aan om hun passie te (her)ontdekken, in hun kracht te
          staan en hernieuwde energie te vinden voor hun werk.
        </Text>
        <Text size="md">
          In december 2023 lanceerde ze haar eerste boek: The Career Journal.
          Een werkboek als kompas ter ondersteuning van individuen bij het
          ontwikkelen van zichzelf in hun loopbaan. Met Tsnatee als boegbeeld
          ben je verzekerd van een dynamische en inspirerende ervaring, waarbij
          ze haar expertise inzet om jou en je publiek te begeleiden op een reis
          van groei en zelfontplooiing.
        </Text>
        <Text size="md">
          Zo uniek als haar naam is, zo uniek is de ervaring die Tsnatee voor
          jouw publiek creëert. Inspirerend, hands-on en bomvol positieve
          energie! Tsnatee is te boeken als spreker, trainer en dagvoorzitter.
        </Text>
      </Stack>
    </Box>
  );
}

function Service({ image, link, text }) {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <UnstyledButton component={NavLink} to={link}>
      <Box
        mx="auto"
        sx={(theme) => ({
          background: "#7C0144",
          color: "white",
          textAlign: "center",
          width: matches ? "300px" : "100%",
          aspectRatio: "1/1",
          borderRadius: "5px",
        })}
      >
        <BackgroundImage src={image} radius="5px">
          <Box
            sx={(theme) => ({
              width: matches ? "300px" : "100%",
              aspectRatio: "1/1",
              display: "flex",
              paddingBottom: "25px",
              borderRadius: "5px",
            })}
          >
            <Button
              sx={(theme) => ({
                alignSelf: "flex-end",
              })}
              radius="5px"
              mx="auto"
              variant="default"
            >
              {text}
            </Button>
          </Box>
        </BackgroundImage>
      </Box>
    </UnstyledButton>
  );
}

function Services() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box id="services" pb={50} mx="auto" p={20} w="100%">
      <Stack>
        <Box
          w={matches ? "700px" : "100%"}
          mx="auto"
          mb={50}
          sx={(theme) => ({
            background: "#7C0144",
            color: "white",
            textAlign: "center",
            padding: 5,
            borderRadius: "5px",
          })}
        >
          <Title>Work with Tsnatee.</Title>
        </Box>

        <SimpleGrid
          cols={3}
          mx="auto"
          mb={50}
          w={matches ? "900px" : "100%"}
          spacing="xl"
          breakpoints={[
            { maxWidth: "lg", cols: 3, spacing: "md" },
            { maxWidth: "md", cols: 1, spacing: "md" },
          ]}
        >
          <Service
            image="/service1.jpg"
            text="Spreker"
            link="/services/speaker"
          />
          <Service
            image="/service2.jpg"
            text="Trainer"
            link="/services/trainer"
          />
          <Service
            image="/service3.jpg"
            text="Dagvoorzitter"
            link="/services/presenter"
          />
        </SimpleGrid>
      </Stack>
    </Box>
  );
}

function CareerJournal() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  return (
    <Box
      id="careerjournal"
      my={20}
      mx="auto"
      p={50}
      w={matches ? "900px" : "100%"}
      bg="#FBF4EA"
      sx={(theme) => ({
        borderRadius: "5px",
      })}
    >
      <Flex
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "sm", sm: "lg" }}
        justify={{ base: "space-between", sm: "center" }}
      >
        <Box w="300px" mx="auto">
          <Center>
            <Image src="/careerjournal.png" />
          </Center>
        </Box>
        <Box
          sx={(theme) => ({
            maxWidth: "400px",
          })}
          mx="auto"
        >
          <Stack align="center" gap="xl">
            <Title weight="600" order={2}>
              Career Journal
            </Title>
            <Text size="md" align="center">
              De Career Journal - is je ultieme gids, je doelenplanner, je
              werkboek voor loopbaanontwikkeling en je visionaire partner,
              allemaal in één. Met deze praktische journal breng je jouw
              grootste ambities tot leven door ze om te zetten in concrete
              stappen die je elke dag/week kunt plannen. Zo werk je actief aan
              het realiseren van je persoonlijke en professionele doelen! Van
              het proactiever leren netwerken, tot het beter kunnen promoten van
              jouw skills tot het realiseren van een gezonde work-life balance -
              deze journal ondersteunt je op elke stap van je reis naar succes.
            </Text>
            <Button
              bg="#fda715"
              mt="20px"
              w="150px"
              component={NavLink}
              to="/careerjournal"
            >
              Lees Meer
            </Button>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
}

function Definition() {
  return (
    <Box
      pt={40}
      h={600}
      sx={(theme) => ({
        width: "100%",
        textAlign: "center",
        padding: 5,
      })}
    >
      <Box
        mx="auto"
        sx={(theme) => ({
          width: "700px",

          textAlign: "center",
          padding: 5,
        })}
      >
        <Paper bg="#FDA715" shadow="xl" radius="xs" p="xl" withBorder>
          <Box>
            <Text c="white" ff="Montserrat" size="4rem">
              Tsnatee
            </Text>
            <Text c="white">
              <Text component="span" fw={600}>
                Definition:
              </Text>{" "}
              Perseverance & Strength
            </Text>
            <Text c="white">
              <Text component="span" fw={600}>
                Pronounciation:
              </Text>{" "}
              {"{"}snaa-tay{"}"}
            </Text>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

const testimonials = [
  {
    text: "The facilitator is an energy bomb, an inspiring young woman that makes you wish this workshop was a few hours longer. Truly useful especially for a young professional who could overlook the importance of personal branding!",
    author: "Ellemijn - Marketing Risk & Compliance Lead - Exellys",
  },
  {
    text: "Great presentation. Nice energy. It was really a pleasure. I am very thankful for the energy Tsnatee shared with us and I am grateful that she shared her personal stories with us.",
    author: "Martina - Senior Advisor - Dell Technologies",
  },
  {
    text: "Tsnatee is great, enthusiastic, experienced, can provide information in an interesting way, nice and funny. Tsnatee gives a feeling you want to watch more of her videos, and listen to her advice.",
    author: "Zuzana - Program Manager - Dell Technologies",
  },
  {
    text: "If you're on the lookout for a genuine mix of learning and hands-on experience, along with the ability to apply what you learn right away, Tsnatee is your person. Tsnatee's workshops are more than just educational; they're brimming with enthusiasm, and the fun factor is through the roof!",
    author: "Femke - Talent Development & Account Manager - Exellys",
  },
];

function Testimonials() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  return (
    <Box id="about" mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Stack>
        <Box
          sx={(theme) => ({
            background: "#fda715",
            color: "white",
            textAlign: "center",
            padding: 5,
            borderRadius: "5px",
          })}
        >
          <Title>Testimonials</Title>
        </Box>

        <Box mt="20px" mx="auto" w="100%">
          <Carousel
            align="center"
            maw={600}
            height="auto"
            slideGap="lg"
            draggable={true}
            withIndicators
            loop
            withControls={false}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            styles={{
              indicator: {
                marginTop: "10px",
                background: "#fada01",
              },
            }}
          >
            {testimonials.map((testimonial, index) => (
              <Carousel.Slide key={index}>
                <Box
                  sx={(theme) => ({
                    padding: "10px",
                    fontStyle: "italic",
                    borderRadius: "5px",
                    color: "white",
                  })}
                >
                  <Blockquote
                    color="#7C0144"
                    radius="lg"
                    iconSize={100}
                    cite={testimonial.author}
                    mt="xl"
                  >
                    {testimonial.text}
                  </Blockquote>
                </Box>
              </Carousel.Slide>
            ))}
          </Carousel>
        </Box>
      </Stack>
    </Box>
  );
}

function Home() {
  return (
    <>
      <Hero />
      {/* <IntroductionEnglish /> */}
      <IntroductionDutch />
      <Services />
      <Testimonials />
      <CareerJournal />
      <Contact />
    </>
  );
}

export { Home, CareerJournal };

import {
  Center,
  Title,
  Text,
  Flex,
  Box,
  BackgroundImage,
  Stack,
  Image,
  Button,
  Paper,
  SimpleGrid,
  UnstyledButton,
  List,
  ThemeIcon,
} from "@mantine/core";

import { Carousel } from "@mantine/carousel";

import { useMediaQuery } from "@mantine/hooks";

import { Checks } from "tabler-icons-react";

import Contact from "./ContactForm";

function GoogleCalendar() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box id="calendar" mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Text>Plan nu direct een gesprek in en ontdek de mogelijkheden:</Text>
      <br />
      <Box>
        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0cuXNRjZYvYxdwalWS-62vHkUFHAwUieKcVyQFlfSv8R_BtMBxYj4qt6MWXJS_qHqgVTu3-kiN?gv=true"
          width="100%"
          height="800"
          frameborder="0"
        ></iframe>
      </Box>
    </Box>
  );
}

function Hero() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box>
      <BackgroundImage radius="xs">
        <Box
          bg="linear-gradient(to right, #7b0144, #fada01);"
          sx={(theme) => ({
            height: "400px",
          })}
        >
          <Center>
            <Box
              sx={(theme) => ({
                textAlign: "center",
                paddingTop: "120px",
              })}
            >
              <Title
                order={2}
                c="white"
                size={matches ? "2.5rem" : "1rem"}
                fw="600"
                color="black"
              >
                LET'S MAKE IT HAPPEN, TOGETHER
              </Title>

              <Center>
                <Stack>
                  <Center>
                    <Image
                      maw={100}
                      radius="md"
                      src="./logo_optimized_vector.svg"
                      alt="Logo"
                    />
                  </Center>
                  <Button
                    bg="#fada01"
                    onClick={(event) => {
                      event.preventDefault();
                      const element = document.getElementById("calendar");
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    Plan een gratis kennismakingsgesprek
                  </Button>
                </Stack>
              </Center>
            </Box>
          </Center>
        </Box>
      </BackgroundImage>
    </Box>
  );
}

function ContactPage() {
  return (
    <>
      <Hero />
      <GoogleCalendar />
      <Contact />
    </>
  );
}

export default ContactPage;
